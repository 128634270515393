import Highcharts from 'highcharts'
import Popper from 'popper.js/dist/popper'
window.Popper = Popper

$(document).ready(function(){
  $('#master_navbar .dropdown').each(function(){
    if($(this).find('.dropdown-item').length <= 0){
      $(this).css('display', 'none');
    }
  });
  var charts = [];
  function generate_graph(element_id, type, title, subtitle, ylabel, x_axis, dataseries){
    var title = { text: title };
    var subtitle = { text: subtitle };
    var xAxis = { categories: x_axis };
    var yAxis = { title: { text: ylabel }, stackLabels: {enabled: true}, plotLines: [{ value: 1, width: 2, color: '#808080' }] };
    var chart = { reflow: true, type: 'column' };
    var tooltip = { valueSuffix: '' };
    var plotOptions = {borderWidth: 0, stacking: 'normal', dataLabels: {enabled: true, format: '{point.y:.1f}%'}};
    var legend = { layout: 'vertical', align: 'right', verticalAlign: 'top', borderWidth: 0, enabled: false };
    var series =  dataseries;
    var json = { title: title, subtitle: subtitle, xAxis: xAxis, yAxis: yAxis, tooltip: tooltip, legend: legend, series: series, chart: chart};
    charts.push(Highcharts.chart(element_id, json));
  }

  //$('.policy-graph-data').each(function(){
  //  var data = JSON.parse($(this).val());
  //  generate_graph( 'policy-graph-'+data['duration'], 'policy', 'Policies', '', 'policies sold', data['xAxis'], data['dataseries']);
  //});

  $('.premium-graph-data').each(function(){
    var data = JSON.parse($(this).val());
    generate_graph('premium-graph-'+data['duration'], 'Premium', 'Premium', '', 'premium collected', data['xAxis'], data['dataseries']);
  });

  charts.forEach(function(chrt){
    chrt.reflow();
  });
});

